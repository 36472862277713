export enum ERROR_KEY {
	DEFAULT = 'default',
	AFFIRM_ACTION_REQUIRED = 'affirmActionRequired',
	ACCOUNT_EXISTS_CHECKOUT_ERROR = 'accountExistsCheckoutError',
	ACCOUNT_EXISTS_SET_UP_ONLINE_ACCESS_ERROR = 'accountExistsSetupOnlineAccessError',
	ACCOUNT_EXISTS_ERROR = 'accountExistsError',
	AVS_ATTEMPT_FAILED = 'AVS_ATTEMPT_FAILED',
	BLOCKED_CUSTOMER_ERROR = 'blockedCustomerError',
	CREATE_QUOTE_DISABLED_ERROR = 'createQuoteDisabledError',
	DELIVERY_ADDRESS_RESTRICTED_BY_INVENTORY = 'deliveryAddressRestrictedByInventory',
	DUPLICATE_GIFT_CARD_ERROR = 'duplicateGiftCardError',
	DUPLICATE_GIFT_CARD_PAYMENT = 'duplicateGiftCardPayment',
	DUPLICATE_QUOTE_CONTACT = 'duplicateQuoteContactError',
	DUPLICATE_CONTACT = 'duplicateContactError',
	FALLBACK_ERROR = 'fallbackError',
	FINANCE_ACTION_REQUIRED = 'financeActionRequired',
	EMAIL_OR_PASSWORD_NOT_FOUND = 'emailOrPasswordNotFoundError',
	FINANCIAL_TRANSACTION_CANCELLED = 'financialTransactionCancelled',
	FINANCIAL_TRANSACTION_CONTACTLESS_REMOVED_TOO_SOON = 'financialTransactionContactlessRemovedTooSoon',
	FINANCIAL_TRANSACTION_DECLINED = 'financialTransactionDeclined',
	FINANCIAL_TRANSACTION_ERROR = 'financialTransactionError',
	FINANCIAL_TRANSACTION_INVALID_CARD_INFORMATION = 'financialTransactionInvalidCardInformation',
	FINANCIAL_TRANSACTION_SAP_CONNECTION_ERROR = 'financialTransactionSapConnectionError',
	FINANCIAL_TRANSACTION_TERMINAL_BUSY = 'financialTransactionTerminalBusy',
	FINANCIAL_TRANSACTION_TIMED_OUT = 'financialTransactionTimedOut',
	FINANCIAL_TRANSACTION_WITH_TOKEN_DECLINED = 'financialTransactionWithTokenDeclined',
	GIFT_CARD_ALREADY_REDEEMED = 'giftCardAlreadyRedeemed',
	GIFT_CARD_INVALID_PRODUCT_ERROR = 'giftCardInvalidProductError',
	GIFT_CARD_TEMPORARILY_BLOCKED = 'giftCardTemporarilyBlocked',
	GIFT_CARD_TOTAL_ERROR = 'giftCardTotalError',
	GIFT_CARD_VENDOR_TIMEOUT = 'giftCardVendorTimeout',
	HOT_PAYMENTS_ERROR = 'hotPaymentsError',
	HOT_REFUNDS_ERROR = 'hotRefundsError',
	ILLEGAL_CREATE_QUOTE_ITEMS_ERROR = 'illegalCreateQuoteItemsError',
	ILLEGAL_CREATE_QUOTE_PRICING_CONDITION_ERROR = 'illegalCreateQuotePricingConditionError',
	INSUFFICIENT_FUNDS_ON_GIFT_CARD = 'insufficientFundsOnGiftCard',
	INSUFFICIENT_PAYMENT_ERROR = 'insufficientPaymentError',
	INVALID_BILLING_ADDRESS_ERROR = 'invalidBillingAddressError',
	INVALID_EMAIL_ERROR = 'invalidEmailError',
	// Physical gift cards
	INVALID_GIFT_CARD_NUMBER = 'invalidGiftCardNumber',
	INVALID_GIFT_CARD_NUMBER_OR_PIN = 'invalidGiftCardNumberOrPin',
	INVALID_GIFT_CARD_UPC = 'invalidGiftCardUpc',
	LINE_ITEM_SIDEMARKING_EXISTS_ERROR = 'lineItemSidemarkingExistsError',
	MAX_AVS_ATTEMPTS_REACHED = 'MAX_AVS_ATTEMPTS_REACHED',
	NON_DELIVERABLE_CERTIFICATE_OF_INSURANCE_ERROR = 'nonDeliverableCertificateOfInsuranceError',
	// Version mismatch.
	OPTIMISTIC_LOCK_FAILURE = 'optimisticLockFailure',
	ORDER_CONFIRMATION_FAILURE = 'orderConfirmationFailure',
	ORDER_EDIT_PATCH_FAILED_NEW_ORDER_CREATED_ERROR_KEY = 'orderEditPatchFailedNewOrderCreatedErrorKey',
	PAYMENTS_EXCEED_AMOUNT_DUE = 'paymentsExceedAmountDue',
	PLACED_ORDER_NOT_FOUND = 'placedOrderNotFound',
	REFUND_REQUIRES_FINANCE = 'refundRequiresFinance',
	// Web only.
	RESOURCE_LOCKED = 'resourceLocked',
	// Engage only.
	RESOURCE_LOCKED_BY_USER = 'resourceLockedByUser',
	ROUTING_ERROR = 'routingError',
	SALESFORCE_INVALID_EMAIL = 'salesforceInvalidEmail',
	SAP_CONNECTION_ERROR = 'sapConnectionError',
	SHAREPOINT_CONNECTION_ERROR = 'sharepointConnectionError',
	SHAREPOINT_AUTH_ERROR = 'authenticationFailed',
	TRANSPORTATION_ZONE_FORBIDDEN = 'transportationZoneForbidden',
	USERNAME_OR_PASSWORD = 'usernameOrPassword',
	USERNAME_OR_PASSWORD_NOT_FOUND = 'usernameOrPasswordNotFoundError',
	USERNAME_UNIQUE_CONSTRAINT_ERROR = 'username',
	VALIDATION_FAILURE = 'validationFailure',
	WEB_CREDIT_CARD_DECLINED_ERROR = 'webCreditCardDeclinedError',
	WEB_CREDIT_CARD_EXPIRED_ERROR = 'webCreditCardExpiredError',
}

export const isValidErrorKey = (
	subject: ERROR_KEY | string | undefined,
): subject is ERROR_KEY => {
	return Boolean(subject && (Object.values(ERROR_KEY).includes(subject) || Object.keys(ERROR_KEY).includes(subject)));
};

export type ErrorObject = {
	errorKey: ERROR_KEY
	errorMessage: string
	errorValues?: Record<string, string | number | boolean | null>
	debugMessage?: string
}

export type ErrorDto = {
	errors: ErrorObject[]
};

export const isErrorObjectType = (
	subject: unknown,
): subject is ErrorObject => {
	if (!subject) {
		return false;
	}
	return Boolean(subject && typeof subject === 'object' && 'errorKey' in subject);
};

export const isErrorObjectTypes = (
	subject: unknown,
): subject is ErrorObject[] => {
	if (!subject || !Array.isArray(subject)) {
		return false;
	}
	return subject.every(item => isErrorObjectType(item));
};

export const isErrorDtoType = (
	subject: unknown,
): subject is ErrorDto => {
	if (
		!subject
		|| typeof subject !== 'object'
		|| !('errors' in subject)
		|| !subject.errors
		|| !Array.isArray(subject.errors)
	) {
		return false;
	}
	return subject.errors.every((error) => {
		if (!error || typeof error !== 'object' || !('errorKey' in error) || typeof error.errorKey !== 'string') {
			return false;
		}
		return isValidErrorKey(error.errorKey);
	});
};
