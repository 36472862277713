import cn from 'classnames';

import { TextButton } from '~/components/Buttons/Components/TextButton';

type Props = {
	inline: boolean
	values: {
		onTryAgainClick: () => void
	}
};

export const InvalidGiftCardUpcMessage = ({ inline, values: { onTryAgainClick } }: Props) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			<span>Unable to read barcode. Please </span>
			<TextButton
				type="button"
				onClick={onTryAgainClick}
			>
				try again
			</TextButton>
			.{' '}
			<span>If the problem persists, try a new gift card or open a Help Desk ticket.</span>
		</div>
	);
};
