import { isEngage } from '~/global/global.constants';
import { modelNamespace } from '~/util/modelNamespace';

/**
 * Everything is pivoted on this. This is coming from the server.
 */
export enum UI_KEY {
	ADD_TO_DELIVERY = 'ADD_TO_DELIVERY',
	SIGN_IN = 'SIGN_IN',
	DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
	DELIVERY_INFO = 'DELIVERY_INFO',
	BILLING_PAYMENT = 'BILLING_PAYMENT',
	BILLING_ADDRESS = 'BILLING_ADDRESS',
	REVIEW_ORDER = 'REVIEW_ORDER',
	PAYMENT = 'PAYMENT',
	ENGAGE_PREPARE = 'ENGAGE_PREPARE',
	ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
}

export type UiKey = {
	[key in UI_KEY]: string
};

type WizardUiKey = Omit<UiKey, UI_KEY.SIGN_IN | UI_KEY.ADD_TO_DELIVERY | UI_KEY.ENGAGE_PREPARE>;

export const SLUG: UiKey = {
	ADD_TO_DELIVERY: '',
	SIGN_IN: 'sign-in',
	ENGAGE_PREPARE: 'prepare',
	DELIVERY_ADDRESS: 'delivery-address',
	DELIVERY_INFO: 'delivery-info',
	BILLING_PAYMENT: 'billing-payment',
	BILLING_ADDRESS: 'billing-address',
	REVIEW_ORDER: 'review-order',
	PAYMENT: 'payment',
	ORDER_CONFIRMATION: 'confirmation',
};

export enum USER_FLOW {
	ADD_TO_DELIVERY_FLOW = 'ADD_TO_DELIVERY_FLOW',
	CHECKOUT_FLOW = 'CHECKOUT_FLOW',
	PAY_AND_GO_FLOW = 'PAY_AND_GO_FLOW',
}

type PathnameType = {
	[key in USER_FLOW]: string
}

export const PATHNAME: PathnameType = {
	ADD_TO_DELIVERY_FLOW: '/add-to-delivery',
	CHECKOUT_FLOW: '/checkout',
	PAY_AND_GO_FLOW: '/checkout',
} as const;

type RouteItem = {
	pathname: string
	slug: string | null
	title: string
}

export type Route = {
	[key in UI_KEY]: RouteItem
}

export type AddToDeliveryRoute = Pick<
	Route,
	UI_KEY.ADD_TO_DELIVERY
	| UI_KEY.BILLING_PAYMENT
	| UI_KEY.REVIEW_ORDER
	| UI_KEY.ORDER_CONFIRMATION
>;

type WebUserFlow = USER_FLOW.ADD_TO_DELIVERY_FLOW | USER_FLOW.CHECKOUT_FLOW

export type Routes = WebRoutes | EngageRoutes;

export type WebRoutes = {
	[key in WebUserFlow]: key extends USER_FLOW.ADD_TO_DELIVERY_FLOW
	?
	AddToDeliveryRoute
	:
	Omit<Route, UI_KEY.BILLING_ADDRESS | UI_KEY.PAYMENT | UI_KEY.ENGAGE_PREPARE>
}

export type EngageRoutes = {
	[key in USER_FLOW]:
	key extends USER_FLOW.ADD_TO_DELIVERY_FLOW
	?
	Omit<AddToDeliveryRoute, UI_KEY.BILLING_PAYMENT>
	:
	key extends USER_FLOW.PAY_AND_GO_FLOW
	?
	Pick<Route, UI_KEY.REVIEW_ORDER | UI_KEY.PAYMENT | UI_KEY.ORDER_CONFIRMATION>
	:
	Omit<Route, UI_KEY.BILLING_PAYMENT>
}

const WEB_ROUTES: WebRoutes = {
	ADD_TO_DELIVERY_FLOW: {
		ADD_TO_DELIVERY: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.ADD_TO_DELIVERY}`,
			slug: SLUG.ADD_TO_DELIVERY,
			title: 'Add to Delivery',
		},
		REVIEW_ORDER: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.REVIEW_ORDER}`,
			slug: SLUG.REVIEW_ORDER,
			title: 'Review Order',
		},
		BILLING_PAYMENT: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.BILLING_PAYMENT}`,
			slug: SLUG.BILLING_PAYMENT,
			title: 'Billing & Payment',
		},
		ORDER_CONFIRMATION: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.ORDER_CONFIRMATION}`,
			slug: SLUG.ORDER_CONFIRMATION,
			title: 'Order Confirmation',
		},
	},
	CHECKOUT_FLOW: {
		ADD_TO_DELIVERY: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.ADD_TO_DELIVERY}`,
			slug: SLUG.ADD_TO_DELIVERY,
			title: 'Add to Delivery',
		},
		SIGN_IN: {
			pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.SIGN_IN}`,
			slug: SLUG.SIGN_IN,
			title: 'Sign In',
		},
		DELIVERY_ADDRESS: {
			pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.DELIVERY_ADDRESS}`,
			slug: SLUG.DELIVERY_ADDRESS,
			title: 'Delivery Address',
		},
		DELIVERY_INFO: {
			pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.DELIVERY_INFO}`,
			slug: SLUG.DELIVERY_INFO,
			title: 'Delivery Information',
		},
		BILLING_PAYMENT: {
			pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.BILLING_PAYMENT}`,
			slug: SLUG.BILLING_PAYMENT,
			title: 'Billing & Payment',
		},
		REVIEW_ORDER: {
			pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.REVIEW_ORDER}`,
			slug: SLUG.REVIEW_ORDER,
			title: 'Review Order',
		},
		ORDER_CONFIRMATION: {
			pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.ORDER_CONFIRMATION}`,
			slug: SLUG.ORDER_CONFIRMATION,
			title: 'Order Confirmation',
		},
	},
} as const;

const engageCheckoutFlow = {
	ADD_TO_DELIVERY: {
		pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.ADD_TO_DELIVERY}`,
		slug: SLUG.ADD_TO_DELIVERY,
		title: 'Add to Delivery',
	},
	SIGN_IN: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.SIGN_IN}`,
		slug: SLUG.SIGN_IN,
		title: 'Sign In',
	},
	ENGAGE_PREPARE: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.ENGAGE_PREPARE}`,
		slug: SLUG.ENGAGE_PREPARE,
		title: 'Prepare',
	},
	DELIVERY_ADDRESS: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.DELIVERY_ADDRESS}`,
		slug: SLUG.DELIVERY_ADDRESS,
		title: 'Delivery Address',
	},
	DELIVERY_INFO: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.DELIVERY_INFO}`,
		slug: SLUG.DELIVERY_INFO,
		title: 'Delivery Information',
	},
	BILLING_ADDRESS: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.BILLING_ADDRESS}`,
		slug: SLUG.BILLING_ADDRESS,
		title: 'Billing Address',
	},
	REVIEW_ORDER: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.REVIEW_ORDER}`,
		slug: SLUG.REVIEW_ORDER,
		title: 'Review Order',
	},
	PAYMENT: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.PAYMENT}`,
		slug: SLUG.PAYMENT,
		title: 'Payment',
	},
	ORDER_CONFIRMATION: {
		pathname: `${PATHNAME.CHECKOUT_FLOW}/${SLUG.ORDER_CONFIRMATION}`,
		slug: SLUG.ORDER_CONFIRMATION,
		title: 'Order Confirmation',
	},
} as const;

const {
	ADD_TO_DELIVERY,
	SIGN_IN,
	DELIVERY_ADDRESS,
	DELIVERY_INFO,
	BILLING_ADDRESS,
	...engagePayAndGoFlow
} = engageCheckoutFlow;

const ENGAGE_ROUTES: EngageRoutes = {
	ADD_TO_DELIVERY_FLOW: {
		ADD_TO_DELIVERY: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.ADD_TO_DELIVERY}`,
			slug: SLUG.ADD_TO_DELIVERY,
			title: 'Add to Delivery',
		},
		REVIEW_ORDER: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.REVIEW_ORDER}`,
			slug: SLUG.REVIEW_ORDER,
			title: 'Review Order',
		},
		ORDER_CONFIRMATION: {
			pathname: `${PATHNAME.ADD_TO_DELIVERY_FLOW}/${SLUG.ORDER_CONFIRMATION}`,
			slug: SLUG.ORDER_CONFIRMATION,
			title: 'Order Confirmation',
		},
	},
	CHECKOUT_FLOW: engageCheckoutFlow,
	PAY_AND_GO_FLOW: engagePayAndGoFlow,
} as const;

const routesObj: Routes = isEngage ? ENGAGE_ROUTES : WEB_ROUTES;

const isWebUserFlowType = (subject: USER_FLOW | WebUserFlow): subject is WebUserFlow => {
	return subject !== USER_FLOW.PAY_AND_GO_FLOW;
};

export const ROUTES: Routes = new Proxy(routesObj, {
	get(_, prop: USER_FLOW) {
		console.info('in route proxy', isEngage, prop);
		if (isEngage) {
			if (prop in ENGAGE_ROUTES) {
				return ENGAGE_ROUTES[prop];
			}
			console.warn('Route not found in ENGAGE_ROUTES:', prop);
		} else {
			if (prop in WEB_ROUTES && isWebUserFlowType(prop)) {
				return WEB_ROUTES[prop];
			}
			console.warn('Route not found in WEB_ROUTES:', prop);
		}
		return {};
	},
});

export const isEngageRoutesType = (
	obj: Routes,
): obj is EngageRoutes => {
	return 'PAYMENT' in obj.CHECKOUT_FLOW;
};

export const WIZARD_DISPLAY_NAME: WizardUiKey = {
	DELIVERY_ADDRESS: 'Delivery Address',
	DELIVERY_INFO: 'Delivery Information',
	BILLING_PAYMENT: 'Billing & Payment',
	BILLING_ADDRESS: 'Billing Address',
	REVIEW_ORDER: 'Review Order',
	PAYMENT: 'Payment',
	ORDER_CONFIRMATION: 'Confirmation',
} as const;

// Returns true if the UI_KEY is being used to display the Wizard steps.
export const isWizardUiKeyType = (
	key: keyof UiKey,
): key is keyof WizardUiKey => {
	if (key === UI_KEY.SIGN_IN) {
		return false;
	}
	if (key === UI_KEY.ENGAGE_PREPARE) {
		return false;
	}
	if (key === UI_KEY.ADD_TO_DELIVERY) {
		return false;
	}
	return true;
};

type WizardDisplayNameKeys = keyof typeof WIZARD_DISPLAY_NAME;
// Ensure strict values.
export type WizardDisplayName = typeof WIZARD_DISPLAY_NAME[WizardDisplayNameKeys];

export const WIZARD_DISPLAY_NAME_SHORT: WizardUiKey = {
	DELIVERY_ADDRESS: 'Address',
	DELIVERY_INFO: 'Delivery',
	BILLING_PAYMENT: 'Payment',
	BILLING_ADDRESS: 'Billing',
	REVIEW_ORDER: 'Review',
	PAYMENT: 'Payment',
	ORDER_CONFIRMATION: 'Confirm',
} as const;

export const WIZARD_DISPLAY_NAME_TRACKING: WizardUiKey = {
	get DELIVERY_ADDRESS() {
		return '1. delivery address';
	},
	get DELIVERY_INFO() {
		return '2. delivery information';
	},
	get BILLING_ADDRESS() {
		return '3. billing address';
	},
	get BILLING_PAYMENT() {
		return '3. billing & payment';
	},
	get REVIEW_ORDER() {
		return '4. review order';
	},
	get PAYMENT() {
		return '5. payment';
	},
	get ORDER_CONFIRMATION() {
		if (isEngage) {
			return '6. confirmation';
		}
		return '5. confirmation';
	},
} as const;

type WizardDisplayNameTrackingKeys = keyof typeof WIZARD_DISPLAY_NAME;
// Ensure strict values.
export type WizardDisplayNameTracking = typeof WIZARD_DISPLAY_NAME[WizardDisplayNameTrackingKeys];

export enum WIZARD_PROGRESS {
	ACTIVE = 'ACTIVE',
	COMPLETE = 'COMPLETE',
	INACTIVE = 'INACTIVE',
}

export enum CONTACT_METHOD_NAME {
	EMAIL = 'EMAIL',
	PRIMARY_PHONE = 'PRIMARY_PHONE',
}

export enum DELIVERY_METHOD {
	UPS = 'UPS',
	UPS_SECOND_DAY = 'UPS_SECOND_DAY',
	UPS_NEXT_DAY = 'UPS_NEXT_DAY',
}

export enum DELIVERY_METHOD_GROUP {
	IS_UPS = 'IS_UPS',
	IS_UPS_SECOND_DAY = 'IS_UPS_SECOND_DAY',
	IS_UPS_NEXT_DAY = 'IS_UPS_NEXT_DAY',
}

export const PAYMENT_OPTION = {
	AFFIRM: 'AFFIRM',
	AFFIRM_DISPUTE: 'AFFIRM_DISPUTE',
	AFFIRM_TRANSACTION_ISSUE: 'AFFIRM_TRANSACTION_ISSUE',
	CASH: 'CASH',
	CHECK: 'CHECK',
	CHECK_RETURNED: 'CHECK_RETURNED',
	CHECK_STOP_PAYMENT: 'CHECK_STOP_PAYMENT',
	CORPORATE_REFUND_CHECK: 'CORPORATE_REFUND_CHECK',
	CREDIT_CARD: 'CREDIT_CARD',
	CREDIT_CARD_CHARGEBACK: 'CREDIT_CARD_CHARGEBACK',
	CREDIT_CARD_TRANSACTION_CLEARING: 'CREDIT_CARD_TRANSACTION_CLEARING',
	COUPON: 'COUPON',
	EMV_CREDIT_CARD: 'EMV_CREDIT_CARD',
	GIFT_CARD: 'GIFT_CARD',
	GIFT_CERTIFICATE: 'GIFT_CERTIFICATE',
	INSUFFICIENT_FUNDS_CHECK: 'INSUFFICIENT_FUNDS_CHECK',
	MANUAL_AUTHORIZATION: 'MANUAL_AUTHORIZATION',
	OTHER: 'OTHER',
	STAFF_MEMBER_FINANCING_PROGRAM: 'STAFF_MEMBER_FINANCING_PROGRAM',
	TRANSFER: 'TRANSFER',
	WIRE_TRANSFER: 'WIRE_TRANSFER',
	WRITE_OFF: 'WRITE_OFF',
} as const;

export type PaymentOption = typeof PAYMENT_OPTION;

export type PaymentOptions = keyof PaymentOption;

export type ActivePaymentOption = Extract<PaymentOptions, 'CREDIT_CARD' | 'AFFIRM'>

export type EngagePaymentOptions = Exclude<PaymentOptions, 'AFFIRM'>

type PaymentOptionDisplay = {
	[key in PaymentOptions]: string
};

export const PAYMENT_OPTION_DISPLAY: PaymentOptionDisplay = {
	AFFIRM: 'Affirm Financing',
	AFFIRM_DISPUTE: 'Affirm Dispute',
	AFFIRM_TRANSACTION_ISSUE: 'Affirm Financing',
	CASH: 'Cash',
	CORPORATE_REFUND_CHECK: 'Refund check',
	CHECK: 'Check',
	CHECK_RETURNED: 'Returned Check',
	CHECK_STOP_PAYMENT: 'Returned Check',
	CREDIT_CARD: 'Credit card',
	CREDIT_CARD_CHARGEBACK: 'Credit Card Chargeback',
	CREDIT_CARD_TRANSACTION_CLEARING: 'Misc. Adjustment',
	COUPON: 'Voucher',
	EMV_CREDIT_CARD: 'Credit card',
	GIFT_CARD: 'R&B Gift Card',
	GIFT_CERTIFICATE: 'Gift Certificate',
	INSUFFICIENT_FUNDS_CHECK: 'Returned Check',
	MANUAL_AUTHORIZATION: 'Credit Card',
	OTHER: 'Other',
	STAFF_MEMBER_FINANCING_PROGRAM: 'Staff Member Financing Program',
	TRANSFER: 'Transfer funds/refunds between orders',
	WIRE_TRANSFER: 'Wire Transfer',
	WRITE_OFF: 'Write-Off',
} as const;

export const REFUND_OPTION = {
	AFFIRM: 'AFFIRM',
	CREDIT_CARD: 'CREDIT_CARD',
	CORPORATE_REFUND_CHECK: 'CORPORATE_REFUND_CHECK',
	DIGITAL_GIFT_CARD: 'DIGITAL_GIFT_CARD',
	PHYSICAL_GIFT_CARD: 'PHYSICAL_GIFT_CARD',
} as const;

export type RefundOption = typeof REFUND_OPTION;

export type RefundOptions = keyof RefundOption;

type RefundOptionDisplay = {
	[key in RefundOptions]: string
};

export const REFUND_OPTION_DISPLAY: RefundOptionDisplay = {
	AFFIRM: 'Affirm financing',
	CREDIT_CARD: 'Credit card',
	CORPORATE_REFUND_CHECK: 'Refund check',
	DIGITAL_GIFT_CARD: 'R&B Gift Card',
	PHYSICAL_GIFT_CARD: 'R&B Gift Card',
} as const;

export enum PAYMENT_AMOUNT_TYPE {
	MINIMUM_DUE = 'MINIMUM_DUE',
	ORDER_TOTAL = 'ORDER_TOTAL',
}

export enum BILLING_ADDRESS_SELECTION {
	BILLING_NEW = 'BILLING_NEW',
	BILLING_ON_FILE = 'BILLING_ON_FILE',
	BILLING_ON_FILE_MODIFIED = 'BILLING_ON_FILE_MODIFIED',
	BILLING_SAME_AS_DELIVERY = 'BILLING_SAME_AS_DELIVERY',
}

export const CARD_TYPE = {
	AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
	DISCOVER: 'DISCOVER',
	GIFT_CARD: 'GIFT_CARD',
	MASTERCARD: 'MASTERCARD',
	VISA: 'VISA',
} as const;

export type CardType = typeof CARD_TYPE;
export type CardTypes = keyof CardType;
export type CreditCardTypes = Exclude<CardTypes, 'GIFT_CARD'>

type CardDisplayType = {
	[key in CardTypes]: string
}

export const CARD_DISPLAY_TYPE: CardDisplayType = {
	AMERICAN_EXPRESS: 'American Express',
	DISCOVER: 'Discover',
	GIFT_CARD: 'R&B Gift Card',
	MASTERCARD: 'Mastercard',
	VISA: 'Visa',
} as const;

export const CARD_DISPLAY_TYPE_SHORT: CardDisplayType = {
	...CARD_DISPLAY_TYPE,
	AMERICAN_EXPRESS: 'Amex',
} as const;

export enum DELIVERY_INFO_TYPE {
	IN_HOME = 'IN_HOME',
	// @deprecated SMS_DELIVERY_NOTIFICATIONS
	CONTACT_PREFERENCE = 'CONTACT_PREFERENCE',
	DELIVERY_NOTIFICATIONS = 'DELIVERY_NOTIFICATIONS',
	ORDER_NOTE = 'ORDER_NOTE',
	PICKUP_AT_DC = 'PICKUP_AT_DC',
	TAKE_HOME = 'TAKE_HOME',
	UPS = 'UPS',
	UPS_SECOND_DAY = 'UPS_SECOND_DAY',
	UPS_NEXT_DAY = 'UPS_NEXT_DAY',
	UPS_NO_EXPEDITE = 'UPS_NO_EXPEDITE',
	UPS_OVERSIZE = 'UPS_OVERSIZE',
	USPS = 'USPS',
}

export enum READ_ONLY_DELIVERY_INFO_TEMPLATE {
	DEFAULT = 'DEFAULT',
	MINIMAL = 'MINIMAL',
}

export enum ERROR_BLURB_KEYS {
	ADD_TO_DELIVERY_AVAILABILITY_CHANGED = 'ADD_TO_DELIVERY_AVAILABILITY_CHANGED',
	ADD_TO_DELIVERY_NEW_ORDER_CREATED = 'ADD_TO_DELIVERY_NEW_ORDER_CREATED',
	ADD_TO_DELIVERY_PAYMENT_ERROR = 'ADD_TO_DELIVERY_PAYMENT_ERROR',
	LINE_ITEM_AVAILABILITY_CHANGED = 'LINE_ITEM_AVAILABILITY_CHANGED',
	SCHEDULED_DELIVERY_DATE_CHANGED = 'SCHEDULED_DELIVERY_DATE_CHANGED',
}

// This is here and not within its own store file to avoid circular dependencies.
export const reviewOrderStoreNamespace = `${modelNamespace.CHECKOUT}/ReviewOrder/ReviewOrderStore`;
